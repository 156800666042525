<template>
  <div class="login-form">
    <div class="pt-lg-0 pt-5">
      <h2 class="bold-text text-dark">Welcome to TestApp.io</h2>
      <div class="text-muted bold-text font-size-h4">
        New here?
        <router-link
          class="text-primary bold-text mx-2"
          :to="{ name: 'signup', params: { email: email } }"
          >Create an Account</router-link
        >
      </div>
    </div>
    <vue-inline-alert
      class="mt-4"
      v-if="getAuthMessage.message"
      :message-type="getAuthMessage.type"
      :message="getAuthMessage.message"
    />
    <!--  -->
    <v-card v-if="tab === 1" tile flat class="transparent mt-15">
      <div class="font-size-h3">Let's check your email.</div>
      <div @keypress.enter="checkEmail" class="mt-7">
        <v-text-field
          v-model="email"
          filled
          id="checkEmail"
          autofocus
          placeholder="Enter your email address"
          v-validate="'required|email'"
          v-bind="veeValidate('Email', 'Email')"
        ></v-text-field>
        <div class="form-group d-flex flex-wrap justify-center align-center">
          <v-btn
            :loading="busy"
            id="nextButton"
            :disabled="busy || errors.any()"
            @click="checkEmail"
            class="main primary"
          >
            Next
          </v-btn>
        </div>
        <div>
          <social-auth
            button-text="Sign in with"
            @loading="busy = $event"
            default-signin
          ></social-auth>
        </div>
      </div>
    </v-card>
    <v-card v-if="tab === 2" tile flat class="transparent mt-15">
      <signin-form
        @change-email="tab = 1"
        default-signin
        :email="email"
        @error="setErrorMessage"
      ></signin-form>
    </v-card>
    <vue-snack-bar></vue-snack-bar>
  </div>
</template>

<script>
import SigninForm from "@/view/components/User/SigninForm";
import veeValidate from "@/mixins/veeValidate";
import { GET_STARTED_INFO } from "@/store/apps/createApp.module";
import SocialAuth from "@/view/components/User/SocialAuth";
import { mapGetters } from "vuex";

export default {
  components: { SigninForm, SocialAuth },
  name: "signin",
  mixins: [veeValidate],
  metaInfo: {
    title: "TestApp.io - Portal Sign In",
    link: [{ rel: "canonical", href: "https://portal.testapp.io/" }],
    meta: [
      {
        name: "description",
        content:
          "Signin for TestApp.io here. It is an app testing platform where developers can share their app Android (APK) & iOS (IPA) with others & get instant feedback.",
      },
      {
        name: "og:description",
        content:
          "Signin for TestApp.io here. It is an app testing platform where developers can share their app Android (APK) & iOS (IPA) with others & get instant feedback.",
      },
      {
        name: "twitter:description",
        content:
          "Signin for TestApp.io here. It is an app testing platform where developers can share their app Android (APK) & iOS (IPA) with others & get instant feedback.",
      },
      // {
      //   name: "robots",
      //   content: "noindex,nofollow"
      // }
    ],
  },
  data() {
    return {
      errorMessage: "",
      notify: {
        show: false,
        message: "",
        color: "",
      },
      busy: false,
      email: "",
      message: "",
      tab: 1,
    };
  },
  computed: {
    ...mapGetters({
      getAuthMessage: "getAuthMessage",
    }),
  },
  created() {
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.checkEmail();
    }
  },
  methods: {
    setErrorMessage(message) {
      this.errorMessage = message;
    },
    changeEmail() {
      this.tab = 1;
    },
    async checkEmail() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        this.$store
          .dispatch(GET_STARTED_INFO, { email: this.email })
          .then((response) => {
            this.busy = false;
            if (response.signup) {
              this.$router
                .push({
                  name: "signup",
                  params: { email: this.email },
                })
                .catch();
            } else {
              this.tab = 2;
            }
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
            this.busy = false;
          });
      }
    },
  },
};
</script>
